<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.markCertificateOfOccupancy') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="approvedByValidation.required?'has-error':null"
          class="form-group row"
        >
          <label class="col-xs-3 control-label required">{{ $t('string.approvedBy') }}</label>
          <div class="col-xs-9">
            <input
              v-model="issued_by"
              :placeholder="$t('string.approvedBy')"
              class="form-control"
              type="text"
            >
            <template v-if="approvedByValidation.required">
              <label class="label-error">{{ approvedByValidation.message }}</label>
            </template>
          </div>
        </div>
        <div
          :class="issuedNumberKmValidation.required?'has-error':null"
          class="form-group row"
        >
          <label class="col-xs-3 control-label required">{{ $t('string.certificateNumber') }} {{
            $t('string.inKm')
          }}</label>
          <div class="col-xs-9">
            <input
              v-model="issued_number_km"
              :placeholder="$t('string.certificateNumber')"
              class="form-control"
              type="text"
            >
            <template v-if="issuedNumberKmValidation.required">
              <label class="label-error">{{ issuedNumberKmValidation.message }}</label>
            </template>
          </div>
        </div>
        <div
          :class="issuedNumberEnValidation.required?'has-error':null"
          class="form-group row"
        >
          <label class="col-xs-3 control-label required">{{ $t('string.certificateNumber') }} {{
            $t('string.inEn')
          }}</label>
          <div class="col-xs-9">
            <input
              v-model="issued_number_en"
              :placeholder="$t('string.certificateNumber')"
              class="form-control"
              type="text"
            >
            <template v-if="issuedNumberEnValidation.required">
              <label class="label-error">{{ issuedNumberEnValidation.message }}</label>
            </template>
          </div>
        </div>
        <div
          :class="issuedAtValidation.required?'has-error':null"
          class="form-group row"
        >
          <label class="control-label col-sm-3">{{ $t('string.issuedAt') }}</label>
          <div class="col-sm-9">
            <date-picker
              v-model="issued_at"
              :lang="datepickerLocale[$i18n.locale].lang"
              :placeholder="$t('string.issuedAt')"
              class="table-full-width"
              format="DD/MM/YYYY"
            />
            <template v-if="issuedAtValidation.required">
              <label class="label-error">{{ issuedAtValidation.message }}</label>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xs-9 col-xs-offset-3">
            <button
              class="btn btn-primary btn-sm"
              @click="save"
            >
              {{ $t('button.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"

export default {
  name: "Approval",
  mixins: [validationMixins],
  metaInfo() {
    return {
      title: this.$t('string.markCertificateOfOccupancy'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      issued_by: 'ឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា',
      issued_at: null,
      issued_number_km: null,
      issued_number_en: null,
      note: 'ឯកសារនេះបានដាក់អោយប្រើប្រាស់ជាផ្លូវការចាប់ពីពេលនេះទៅ។',
      validations: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/certificate-occupancy`
    },
  },
  methods: {
    save() {
      let issued_at = null
      if (this.$moment(this.issued_at).isValid()) {
        issued_at = this.$moment(this.issued_at).locale('en').format('YYYY-MM-DD')
      }
      this.$isLoading(true)
      this.$axios.post(`${this.api}/approve`, {
        issued_at: issued_at,
        issued_by: this.issued_by,
        issued_number_km: this.issued_number_km,
        issued_number_en: this.issued_number_en,
        occupancy_uuid: this.$route.params.occupancy_uuid,
      }).then(() => {
        this.showToastr()
        this.$router.push({
          name: 'list-certificate-occupancy-province',
        })
      }).catch((error) => {
        this.$isLoading(false)
        this.validations = null
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
}
</script>

<style scoped>

</style>
